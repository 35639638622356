<template>
  <div
    class="flex module"
    :class="{ active: active === $store.state.attendance.form[formName]?.id }"
    @click="onSelect"
  >
    <div class="module-title">{{ $store.state.attendance.form[formName]?.name }}</div>
    <Check class="icon" 
      v-if="$store.state.attendance.form[formName]?.showing" 
      :class="{ icon: true, confirmed: $store.state.attendance.form[formName]?.completed && !customFormEdit }" 
    />
    <Plus v-else class="icon plus" @click="handleVisibilityProps" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { gotToOption } from '@/utils/attendanceHelper'
import Check from '@/assets/icons/check.svg'
import Plus from '@/assets/icons/plus.svg'

export default {
  props: {
    formName: { type: String, required: true },
  },
  components: {
    Check,
    Plus
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      active: state => state.attendance.form.active,
      customFormEdit: state => state.attendance.customFormEdit,
    })
  },
  methods: {
    ...mapActions('attendance/form', ['handleActiveModule', 'updateForms']),
    onSelect(ev) {
      ev.stopPropagation()
      this.handleActiveModule(this.$store.state.attendance.form[this.formName]?.id)
      gotToOption(this.$store.state.attendance.form[this.formName]?.id)
    },
    handleVisibilityProps(ev) {
      ev.stopPropagation()
      if (this.canEdit || this.customFormEdit) {
        this.$store.dispatch(`attendance/form/${this.formName}/handleProps`, ({ key: 'showing', value: true }), { root: true })
        this.$store.dispatch(`attendance/form/${this.formName}/handleProps`, ({ key: 'opened', value: true }), { root: true })
        setTimeout(() => this.onSelect(ev), 500)
        
        if (this.customFormEdit) {
          this.$store.dispatch(`attendance/form/${this.formName}/handleProps`, ({ key: 'customName', value: true }), { root: true })

          this.updateForms([{
            id: this.$store.state.attendance.form[this.formName]?.id,
            name: this.$store.state.attendance.form[this.formName]?.name,
            opened: this.$store.state.attendance.form[this.formName]?.opened,
            showing: this.$store.state.attendance.form[this.formName]?.showing,
            default_text: this.$store.state.attendance.form[this.formName]?.default_text,
          }])
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
  .flex {
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    .icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
      stroke: var(--neutral-500);
      &.plus {
        fill: var(--blue-500);
        stroke: transparent;
      }
      &.confirmed {
        stroke: var(--states-success);
      }
    }
  }
  .module {
    padding: 8px 16px;
    font-weight: 600;
    font-size: 14px;
    color: var(--blue-500);
    cursor: pointer;
    border-left: 4px solid transparent;

    .module-title {
      max-width: 89%;
      font-size: min(1.2vw, 14px);
    }

    &.active {
      font-weight: 700;
      color: var(--type-active);
      border-left: 4px solid var(--orange);
    }
  }
</style>
